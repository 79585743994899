/* eslint-disable max-len */
const React = require('react');
const Image = require('nordic/image');
const Button = require('@andes/button');
const { injectI18n } = require('nordic/i18n');
const Form = require('@andes/form');
const TextField = require('@andes/textfield');

const config = require('../../../config/default');

const restclient = require('nordic/restclient')({
  baseURL: '/api',
  timeout: 5000,
});

class FormSubscriptionView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      errorMsg: '',
      disabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      email: { value: email },
    } = e.target;
    const text = config.defaultText[this.props.location];
    if (this.validateEmailBeforeSend(email)) {
      this.setState({ loading: true, success: false, errorMsg: '' });
      restclient
        .put('/suscribe', {
          data: {
            ml_dev_institutional: 'true',
          },
        })
        .then(res => res.data)
        .then(({ message }) => {
          if (message === 'ok') {
            this.setState({ errorMsg: '', success: true, disabled: true });
          } else {
            this.setState({ errorMsg: text.home[message], success: false });
          }
          this.setState({ loading: false });
        })
        .catch((i18n) => {
          this.setState({
            loading: false,
            errorMsg: i18n.gettext('No fue posible completar la suscripción.'),
            success: false,
          });
        });
    }
  }

  handleChange() {
    this.setState({ success: false, errorMsg: '' });
  }

  validateEmailBeforeSend(email, i18n) {
    if (!/^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,5}$/.test(email)) {
      this.setState({
        errorMsg: i18n.gettext('E-mail inválido'),
        success: false,
      });
      return false;
    }
    return true;
  }

  render() {
    const { user, siteId, redirectUrl, userPreferences, userLogged, i18n } = this.props;
    const { url } = config.fendConfig[siteId];
    const redirectMyAccount = `${config.fendConfig[siteId].url.redirectMyAccount}/communication-preferences`;
    // eslint-disable-next-line no-nested-ternary
    const modifier = this.state.success
      ? 'completed'
      : this.state.errorMsg
        ? 'error'
        : 'readonly';
    const message = this.state.success
      ? i18n.gettext('E-mail inscrito con éxito')
      : this.state.errorMsg || i18n.gettext('Recibe las últimas novedades sobre nuestra API');

    const subscribedOrUnsubscribed = userLogged ? userPreferences.ml_dev_institutional : '';

    return (
      <section className="form-subscription">
        <div id="subscribe" className="mesh-row form-subscription__row">
          <div className="mesh-col-l-6 form-subscription__wrapper">
            <div className="form-subscription__wrapper-img">
              <Image className="form-subscription__img" src="https://http2.mlstatic.com/storage/developers-site-cms-admin/254748852224-Imagen-Newsletter.png" alt="banner footer" />
            </div>
            {userLogged ? (
              <div className="form-subscription__container">
                <h2 className="form-subscription_newsletter">{i18n.gettext('Suscríbete a nuestro Newsletter')}</h2>
                <Form onSubmit={this.handleSubmit}>
                  <div className="form-subscription__oneline-wrapper">
                    <TextField
                      srLabel="Email"
                      name="email"
                      defaultValue={user.email ? user.email : ''}
                      helper={subscribedOrUnsubscribed === 'false' ? message : <p>{i18n.gettext('¡Ya estás suscripto a las novedades de nuestras API! Para dejar de recibirlas,')} <a href={redirectMyAccount} target="_blank" rel="noreferrer noopener" style={{ color: '#3483fa', textDecoration: 'none' }}> {i18n.gettext('ingrese aquí')}</a>.</p>}
                      modifier={modifier}
                      onChange={this.handleChange}
                      onKeyPress={this.handleChange}
                      width={subscribedOrUnsubscribed === 'false' ? null : 100}
                    />
                    {subscribedOrUnsubscribed === 'false'
                      && (
                        <div className="form-subscription__btn-group">
                          <Button
                            modifier="transparent"
                            disabled={this.state.success ? this.state.disabled : this.state.loading}
                            type="submit"
                          >
                            {i18n.gettext('Suscribirme')}
                          </Button>
                        </div>
                      )}
                  </div>
                </Form>
              </div>
            )
              : (
                <div className="form-subscription-notlogged__container">
                  <p className="form-subscription_newsletter">{i18n.gettext('Suscríbete a nuestro Newsletter')}</p>

                  <a className="form-subscription-link-login" href={`https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/login?platform_id=ml&go=${redirectUrl}${encodeURIComponent('#subscribe')}`}>
                    {i18n.gettext('Ingrese')}
                  </a>
                  {i18n.gettext(' o ')}
                  <a className="form-subscription-link-register" href={`${url.registration}?confirmation_url=${redirectUrl}${encodeURIComponent('#subscribe')}`}>
                    {i18n.gettext('regístrate')}
                  </a>
                  {i18n.gettext(' para recibir las últimas novedades sobre nuestra API')}
                </div>
              )}
          </div>
        </div>
      </section>
    );
  }
}

module.exports = injectI18n(FormSubscriptionView);
