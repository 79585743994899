const React = require('react');
const Script = require('nordic/script');
const Page = require('nordic/page');
const moment = require('moment');
const HeadView = require('../../../components/HeadView');
const HeaderView = require('../../../components/HeaderView');
const Footer = require('../../../components/FooterView');
const FormSubscription = require('../../../components/FormSubscription');
const texts = require('../../../../config/default');
const NotCompliant = require('../../../components/Kyc/NotCompliant');

/**
 * IndexView Component
 */
class IndexView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = ({
    });
    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.assignDropdown = this.assignDropdown.bind(this);
  }

  componentDidMount() {
    if (this.props.userIsCompliant) {
      const items = document.getElementsByClassName('solution__list-item');
      const stages = document.getElementsByClassName('solution__list-stage');
      for (let index = 0; index < items.length; index += 1) {
        const item = items[index];
        item.addEventListener('click', () => {
          this.selectItem(items, index);
          this.showStage(stages, index);
        });
      }
      this.assignDropdown(false);
    }
  }

  openDropdown(e) {
    if (this.props.userIsCompliant) {
      document.querySelector('.solution__list').classList.add('dropdown__open');
      e.currentTarget.removeEventListener('click', this.openDropdown);
      window.addEventListener('click', this.closeDropdown, true);
    }
  }

  closeDropdown() {
    if (this.props.userIsCompliant) {
      document.querySelector('.solution__list').classList.remove('dropdown__open');
      window.removeEventListener('click', this.closeDropdown, true);
      window.addEventListener('click', this.assignDropdown);
    }
  }

  assignDropdown(isWindow) {
    if (this.props.userIsCompliant) {
      const $dropdown = document.querySelector('.solution__list');
      if ($dropdown) {
        $dropdown.addEventListener('click', this.openDropdown);
      }
      if (isWindow) {
        window.removeEventListener('click', this.assignDropdown);
      }
    }
  }

  selectItem(items, selectedItemIndex) {
    if (this.props.userIsCompliant) {
      for (let index = 0; index < items.length; index += 1) {
        const stage = items[index];
        stage.className = index === selectedItemIndex ? 'solution__list-item active' : 'solution__list-item';
      }
    }
  }

  showStage(stages, selectedStageIndex) {
    if (this.props.userIsCompliant) {
      for (let index = 0; index < stages.length; index += 1) {
        const stage = stages[index];
        stage.className = index === selectedStageIndex ? 'solution__list-stage active' : 'solution__list-stage';
      }
    }
  }

  render() {
    const {
      device,
      translations,
      locale,
      headerParams,
      siteId,
      user,
      refererURL,
      deviceType,
      page,
      categories,
      location,
      news,
      events,
      platform,
      browser,
      userIsCompliant,
      kycUrl,
      backofficeOnly,
      userPreferences,
      userLogged,
    } = this.props;

    const state = {
      device,
      translations,
      locale,
      headerParams,
      siteId,
      user,
      refererURL,
      deviceType,
      page,
      categories,
      location,
      news,
      events,
      platform,
      browser,
      texts,
      userIsCompliant,
      kycUrl,
      backofficeOnly,
      userPreferences,
      userLogged,
    };

    moment.locale(location);

    const text = texts.defaultText[location];

    return (
      <Page key="index" id="index" name="index" state={state}>
        <HeadView location={location} />
        {/* BODY */}
        <HeaderView
          {...headerParams}
          user={user}
          refererURL={refererURL}
          siteId={siteId}
          categories={categories}
          location={location}
          userLogged={userLogged}
          userIsCompliant={userIsCompliant}
          backofficeOnly={backofficeOnly}
        />
        {userIsCompliant ? (
          <>
            <div className="inner-content" dangerouslySetInnerHTML={{ __html: page.content }} />
            <FormSubscription user={user} location={location} siteId={siteId} redirectUrl={headerParams.redirectUrl} userPreferences={userPreferences} userLogged={userLogged} />
            <Footer user={user} refererURL={refererURL} siteId={siteId} location={location} userLogged={userLogged} />
          </>

        ) : (
          <NotCompliant
            texts={text}
            kycUrl={kycUrl}
            location={location}
            siteId={siteId}
            deviceType={deviceType}
            backofficeOnly={backofficeOnly}
          />
        )}
        <Script src="vendor.js" />
      </Page>
    );
  }
}

module.exports = props => <IndexView {...props} />;
