const React = require('react');

const Congrats = () => (
  <svg width="416" height="83" viewBox="0 0 416 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="208" cy="40" r="40" fill="white" />
    <path d="M189.838 24.7304L189.838 24.7306C189.723 24.7728 189.65 24.8793 189.65 24.9994V40.3349C189.65 48.5798 194.026 53.9144 198.514 57.2426C200.762 58.9096 203.023 60.0585 204.736 60.7933C205.591 61.1601 206.305 61.422 206.807 61.5925C207.294 61.7579 207.578 61.8361 207.593 61.8404L207.594 61.8405L207.599 61.8418C207.616 61.8464 207.639 61.85 207.667 61.85C207.695 61.85 207.718 61.8464 207.735 61.8418L207.74 61.8405L207.741 61.8404C207.755 61.8363 208.039 61.7581 208.527 61.5925C209.029 61.422 209.743 61.1603 210.598 60.7936C212.311 60.0589 214.572 58.9102 216.82 57.2433C221.308 53.9154 225.684 48.5808 225.684 40.3349V24.9994C225.684 24.8793 225.61 24.7728 225.496 24.7306L225.496 24.7304L207.769 18.168C207.769 18.168 207.769 18.1679 207.769 18.1679C207.704 18.144 207.632 18.144 207.567 18.1679C207.567 18.168 207.567 18.168 207.567 18.168L189.838 24.7304Z" fill="#FFE600" stroke="white" strokeWidth="2.3" />
    <path d="M207.667 46.6079C202.523 46.6079 198.354 42.3833 198.354 37.172C198.354 31.9607 202.523 27.7361 207.667 27.7361C212.81 27.7361 216.98 31.9607 216.98 37.172C216.98 42.3833 212.81 46.6079 207.667 46.6079Z" fill="white" stroke="#333333" strokeWidth="1.38" />
    <path fillRule="evenodd" clipRule="evenodd" d="M207.666 38.6632C205.5 38.6632 203.744 36.8839 203.744 34.6889C203.744 32.4939 205.5 30.7146 207.666 30.7146C209.833 30.7146 211.589 32.4939 211.589 34.6889C211.589 36.8839 209.833 38.6632 207.666 38.6632Z" stroke="#333333" strokeWidth="1.38" />
    <path d="M201.294 44.1054V42.9329C201.294 41.8541 202.157 40.9795 203.222 40.9795H212.111C213.175 40.9795 214.039 41.8541 214.039 42.9329V44.1248" stroke="#333333" strokeWidth="1.38" />
    <path d="M189.789 47.8226C188.981 45.625 188.5 43.1392 188.5 40.3349V24.9994C188.5 24.3975 188.874 23.8609 189.439 23.6519L207.169 17.0891C207.491 16.9703 207.845 16.9703 208.167 17.0891L225.895 23.6519C226.46 23.8609 226.834 24.3975 226.834 24.9994V40.3349C226.834 57.7808 208.223 62.9022 208.035 62.9521C207.914 62.9847 207.79 63 207.667 63C207.544 63 207.42 62.9847 207.299 62.9521C207.151 62.913 195.647 59.7462 190.71 49.9442" stroke="#333333" strokeWidth="1.38" />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="220" y="52" width="30" height="30">
      <circle cx="235" cy="67" r="15" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <rect x="220" y="52" width="30" height="30" fill="#E6540B" />
      <path d="M232.727 69.6168L241.308 61.0356L243.237 62.964L232.727 73.4737L226.763 67.5095L228.691 65.581L232.727 69.6168Z" fill="white" />
    </g>
  </svg>

);

module.exports = Congrats;
