const React = require('react');
const Button = require('@andes/button');
const Icon = require('../../svg/NotCompliant');
const config = require('../../../../config/default');
const Congrats = require('../Congrats');

const NotCompliant = ({ texts, kycUrl, siteId, backofficeOnly, deviceType }) => {
  /* istanbul ignore next */
  const handleClick = () => {
    window.location.assign(kycUrl);
  };

  /* istanbul ignore next */
  const handleClickCompleteData = () => {
    const url = `${config.fendConfig[siteId].vendedoresUrl}${config.fendConfig[siteId].pathDocKyc}`;
    window.open(url, '_blank');
  };

  return (
    !backofficeOnly ? (
      <div className="supportnotcompliant__app">
        <Icon />
        <div className="supportnotcompliant_body__app">
          <h2 className="supportnotcompliant_title__app">{texts.messageKyc.titleNotCompliant}</h2><h2>{texts.messageKyc.titleNotCompliantSecond}</h2>
          <p>{texts.messageKyc.bodyNotCompliant}</p>
        </div>
        <Button
          className="start_button__app "
          onClick={handleClick}
        >
          {texts.messageKyc.textButtonStart}
        </Button>
        <a
          className="whycompletedata_link__app "
          onClick={handleClickCompleteData}
        >
          {texts.messageKyc.textLinkWhyCompleteMyData}
        </a>
      </div>
    ) : (
      <Congrats
        texts={texts}
        siteId={siteId}
        deviceType={deviceType}
      />

    )
  );
};

module.exports = NotCompliant;
