const React = require('react');

const NotCompliant = () => (
  <svg width="222" height="105" viewBox="0 0 222 105" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M202.377 74.8152H216.444" stroke="#FFCF03" strokeOpacity="0.5" strokeWidth="1.5" />
    <path d="M209.414 67.7836V81.8808" stroke="#FFCF03" strokeOpacity="0.5" strokeWidth="1.5" />
    <path d="M22.3767 54.8152H36.4436" stroke="#FFCF03" strokeOpacity="0.5" strokeWidth="1.5" />
    <path d="M29.4141 47.7836V61.8808" stroke="#FFCF03" strokeOpacity="0.5" strokeWidth="1.5" />
    <path d="M2.4296 26.8152H27.2535" stroke="#FFCF03" strokeOpacity="0.5" strokeWidth="1.5" />
    <path d="M14.8485 14.5098L14.8485 39.18" stroke="#FFCF03" strokeOpacity="0.5" strokeWidth="1.5" />
    <line x1="222" y1="103.565" x2="4" y2="103.565" stroke="#333333" strokeWidth="1.5" />
    <rect x="53.0839" y="22.9075" width="125.591" height="71.2992" fill="white" stroke="white" strokeWidth="3" />
    <path d="M51.8528 38V95.3493H179.909V84M51.8528 34V21.2316H179.909V73M179.909 77V80" stroke="#333333" strokeWidth="1.5" />
    <path d="M51.8527 18.5341C51.8527 15.9959 53.8967 13.9382 56.4181 13.9382H175.367C177.888 13.9382 179.932 15.9959 179.932 18.5341V21.4763H51.8527V18.5341Z" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M41.6336 95.486C41.6336 100.028 45.2909 103.709 49.8024 103.709H181.983C186.494 103.709 190.151 100.028 190.151 95.486H41.6336Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M50.1388 101.386H181.648C185.291 101.386 188.372 99.4128 189.394 96.6962H189.754L189.751 96.8795C189.632 100.472 186.05 103.355 181.648 103.355H50.1388C45.6624 103.355 42.0336 100.374 42.0336 96.6962H42.3937C43.4155 99.4128 46.4966 101.386 50.1388 101.386Z" fill="#EEEEEE" />
    <path d="M41.6336 95.486C41.6336 100.028 45.2909 103.709 49.8024 103.709H181.983C186.494 103.709 190.151 100.028 190.151 95.486H41.6336Z" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M101.829 95.486C101.829 97.0002 103.05 98.2271 104.554 98.2271H127.23C128.735 98.2271 129.956 97.0002 129.956 95.486H101.829Z" stroke="#333333" strokeWidth="1.5" />
    <ellipse cx="116.26" cy="18.0639" rx="1.14225" ry="1.14896" fill="#333333" />
    <path d="M199.57 21.4225C199.57 31.6352 191.109 39.9669 180.607 39.9669C170.104 39.9669 161.643 31.6352 161.643 21.4225C161.643 11.2099 170.104 2.87817 180.607 2.87817C191.109 2.87817 199.57 11.2099 199.57 21.4225Z" fill="#FFE600" stroke="white" strokeWidth="3" />
    <ellipse cx="180.462" cy="21.4225" rx="20.4634" ry="20.0443" stroke="#333333" strokeWidth="1.5" />
    <path d="M171.501 21.5954L177.473 27.4737L190.163 14.9824" stroke="#333333" strokeWidth="1.5" />
    <rect x="94.7116" y="36.9402" width="38.7084" height="37.6731" rx="3.5" fill="#FFE600" stroke="white" strokeWidth="3" />
    <rect x="93.2116" y="35.4402" width="41.7084" height="40.6731" rx="5" stroke="#333333" strokeWidth="1.5" />
    <ellipse cx="114.797" cy="49.7872" rx="10.2792" ry="10.2113" fill="white" stroke="black" strokeOpacity="0.9" strokeWidth="1.5" />
    <path d="M108.325 57.7417V57.0571C108.325 55.1929 109.897 53.6818 111.836 53.6818H117.613C119.562 53.6818 121.143 55.2008 121.143 57.0747V57.7594" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M114.739 43.3579C117.123 43.3579 119.055 45.2156 119.055 47.5071C119.055 49.7987 117.123 51.6563 114.739 51.6563C112.355 51.6563 110.423 49.7987 110.423 47.5071C110.423 45.2156 112.355 43.3579 114.739 43.3579Z" stroke="#333333" strokeWidth="1.5" />
    <line x1="104.518" y1="66.0729" x2="125.076" y2="66.0729" stroke="black" strokeOpacity="0.9" strokeWidth="1.5" />
    <line x1="108.325" y1="69.8799" x2="122.031" y2="69.8799" stroke="black" strokeOpacity="0.9" strokeWidth="1.5" />
  </svg>
);

module.exports = NotCompliant;
