const React = require('react');
const Image = require('nordic/image');

const userPhoto = ({ picture }) => (
  <div className="user-photo__wrapper">
    <Image
      className="user-photo"
      alt="user"
      src={
        picture || 'https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/366154775951-svgexport-2@2x.png'
      }
    />
  </div>
);

module.exports = userPhoto;
