/* global window, document */
/**
 * Styles
 */
require('../pages/index/index.scss');

/**
 * Polyfills
 */
require('./polyfills');

/**
 * Module dependencies
 */
const React = require('react');
const { AndesProvider } = require('@andes/context');
const { hydrate } = require('nordic/hydrate');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const IndexView = require('../pages/index/components/IndexView');
const { setAnalytics } = require('./helpers/analytics');
const setFacebookPixel = require('./helpers/facebook');

/**
 * Get server state
 */
const preloadedState = window.__PRELOADED_STATE__;
const { location, deviceType, user, page } = preloadedState;
const located = `?lang=${location}`;
const pages = '';

/**
 * Set Analytics
 */
function setAnalyticsClient() {
  setAnalytics(located, pages, deviceType, user);
}

const i18n = new I18n({
  translations: preloadedState.translations,
});

hydrate(
  <AndesProvider locale={preloadedState.locale} device={preloadedState.device}>
    <I18nProvider i18n={i18n}>
      <IndexView {...preloadedState} />
    </I18nProvider>
  </AndesProvider>,
  document.getElementById('root-app'),
  setAnalyticsClient,
  setFacebookPixel(location, page),
);
