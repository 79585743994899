const React = require('react');
const Button = require('@andes/button');
const Card = require('@andes/card');
const config = require('../../../../config/default');
const Icon = require('../../svg/Congrats');

const Congrats = ({ texts, siteId, deviceType }) => {
  /* istanbul ignore next */
  const handleClickCompleteData = () => {
    const url = `${config.fendConfig[siteId].coreUrl}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="supportcongrats__header_background" />
      <section className="support-container">
        <div className="supportcongrats__app">
          <Icon />
          <h2 className="supportnotcompliant_title__app">{texts.messageKyc.textCongratsTitle}</h2>
          <h2>{texts.messageKyc.textCongratsTitleSecond}</h2>
        </div>
        <Card className={`supportnotcompliant__app-congrats ${deviceType}`}>
          <div className="supportnotcompliant_body__app-congrats">
            <p>{texts.messageKyc.textCongratsTimeVerify}</p>
            <p>{texts.messageKyc.textCongratsReady}</p>
          </div>
          <Button
            className="start_button__app-congrats"
            onClick={handleClickCompleteData}
          >
            {texts.messageKyc.textCongratsButtonRedirectHome}
          </Button>
        </Card>
      </section>
    </>
  );
};

module.exports = Congrats;
