const React = require('react');
const Dropdown = require('@andes/dropdown');
const { useI18n } = require('nordic/i18n');

const { DropdownItem } = Dropdown;

const LanguageSelector = () => {
  const { i18n } = useI18n();

  const onChangeHandler = (e, lang) => {
    const langs = {
      en: 'en_us',
      es: 'es_ar',
      po: 'pt_br',
    };
    if (lang === 'po') {
      window.location.replace(`https://developers.mercadolivre.com.br/${langs[lang]}`);
    } else {
      window.location.replace(`/${langs[lang]}`);
    }
  };

  const languageLabel = i18n.gettext('Idioma');

  return (
    <Dropdown
      size="small"
      srLabel={languageLabel}
      onChange={onChangeHandler}
    >
      <DropdownItem value="en" key="lang-en" title="English" />
      <DropdownItem value="es" key="lang-es" title="Español" />
      <DropdownItem value="po" key="lang-po" title="Português" />
    </Dropdown>
  );
};

module.exports = LanguageSelector;
