const React = require('react');
const { injectI18n } = require('nordic/i18n');
const config = require('../../../config/default');

const UserPhoto = require('../UserPhoto');

class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  isDeveloper(user) {
    return user.internal_tags.filter(tag => tag === 'developer').length > 0;
  }

  isSupportCategory(reference) {
    const supportReferences = ['soporte', 'support', 'suporte'];
    return supportReferences.includes(reference);
  }

  render() {
    const { redirectUrl, siteId, user, categories, location, isCBT, userLogged, userIsCompliant, i18n } = this.props;
    const { url } = config.fendConfig[siteId];
    const title = 'Developers';
    const menu = [];
    const path = isCBT ? 'devsite' : location;
    categories?.forEach((category) => {
      if (category.visible) {
        let link = this.isSupportCategory(category.reference) ? url.support : `/${path}/${category.reference}`;
        if (category.name === 'Foro' || category.name === 'Fórum') {
          link = url.foro;
        }
        if (isCBT && category.reference === 'api-docs') {
          link = `/${path}/introduction-globalselling`;
        }

        menu.push(
          <li className="categories-list" key={category.id}>
            <a href={link} data-analytics={category.tags_analitics} className="analytics">
              {category.name}
            </a>
          </li>,
        );
      }
    });
    return (
      <header role="banner" className="nav-header nav-header-sticky">
        <div className="nav-bounds nav-bounds-with-cart nav-bounds-large">
          <a className="nav-logo" href={`/${path}`} tabIndex="0">
            <span>{title}</span>
          </a>
          <input type="checkbox" id="nav-header-menu-switch" />
          <div className="nav-header-menu-wrapper">
            {userIsCompliant && (
              <form className="nav-search" action={`/${path}/search`} method="GET" role="search">
                <input
                  type="text"
                  className="nav-search-input"
                  name="q"
                  maxLength="120"
                  placeholder={i18n.gettext('Buscar...')}
                  autoComplete="off"
                />
                <button type="submit" className="nav-search-btn" tabIndex="0">
                  <i className="nav-icon-search">
                    <span>{i18n.gettext('Buscar')}</span>
                  </i>
                </button>
              </form>
            )}
            {/* eslint-disable-next-line */}
            <label htmlFor="nav-header-menu-switch" aria-controls="nav-header-menu" tabIndex="0">
              <span className="hamburger-top-bread" />
              <span className="hamburger-patty" />
              <span className="hamburger-bottom-bread" />
            </label>
            <nav id="nav-header-menu">
              <a
                className="option-logout mobile-only"
                href={userLogged && `https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/logout?go=${redirectUrl}`}
                rel="nofollow"
              >
                {userLogged && i18n.gettext('Salir')}
              </a>
              {userIsCompliant && (
                <div className="nav-header-categories-wrapper">
                  {menu}
                  <li className="categories-list">
                    <a href={url.news} className="analytics">
                      {i18n.gettext('Novedades')}
                    </a>
                  </li>
                </div>
              )}
              {userLogged && userIsCompliant && (
                <div className="nav-header-user">
                  <label htmlFor="nav-header-user-switch" tabIndex="0">
                    <span>{user.firstName}</span>
                    <i className="nav-icon-user" />
                  </label>
                  <input type="checkbox" id="nav-header-user-switch" />
                  <nav className="nav-header-user-layer">
                    {user.internal_tags && this.isDeveloper(user) && (
                      <div className="nav-header-user-developer-layer">
                        <a className="option-my-account" href={url.myApps} rel="nofollow">
                          {i18n.gettext('Mis Aplicaciones')}
                        </a>
                        <a className="option-support" href={url.support} rel="nofollow">
                          {i18n.gettext('Soporte')}
                        </a>
                      </div>
                    )}
                    <a
                      className="option-logout desktop-only"
                      href={`https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/logout?go=${redirectUrl}`}
                      rel="nofollow"
                    >
                      {i18n.gettext('Salir')}
                    </a>
                  </nav>
                </div>
              )}
              {userLogged && !userIsCompliant && (
                <div className="nav-header-user">
                  <label htmlFor="nav-header-user-switch" tabIndex="0">
                    <span>{user.firstName}</span>
                    <i className="nav-icon-user" />
                  </label>
                  <input type="checkbox" id="nav-header-user-switch" />
                  <nav className="nav-header-user-layer">
                    <a
                      className="option-logout desktop-only"
                      href={`https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/logout?go=${redirectUrl}`}
                      rel="nofollow"
                    >
                      {i18n.gettext('Salir')}
                    </a>
                  </nav>
                </div>
              )}
              {userIsCompliant && (
                <form className="nav-search mobile-only" action={`/${location}/search`} method="GET" role="search">
                  <input
                    type="text"
                    className="nav-search-input"
                    name="q"
                    maxLength="120"
                    placeholder={i18n.gettext('Buscar...')}
                    autoComplete="off"
                  />
                  <button type="submit" className="nav-search-btn" tabIndex="0">
                    <i className="nav-icon-search">
                      <span>{i18n.gettext('Buscar')}</span>
                    </i>
                  </button>
                </form>
              )}
              {userLogged === null && (
                <a
                  className="login-btn"
                  href={`https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/login?platform_id=ml&go=${redirectUrl}`}
                >
                  <span>
                    {i18n.gettext('Ingresar')}<i className="nav-icon-user" />
                  </span>
                </a>
              )}
              {userLogged && (
                <div className="nav-section__user-photo mobile-only">
                  <UserPhoto picture={user.picture} />
                  <span className="user-photo__message">
                    {i18n.gettext('¡ Hola,')} {user.firstName || 'Dev'} !
                  </span>
                </div>
              )}
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

module.exports = injectI18n(HeaderView);
