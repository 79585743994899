/* globals window, document, ga */
class Analytics {
  static setAnalytics(location, page, device, user) {
    /* eslint-disable */
    (function(i, s, o, g, r, a, m) {
      i.GoogleAnalyticsObject = r;
      (i[r] =
        i[r] ||
        function() {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    /* eslint-enable */

    const url = `/${location}/${page}`;

    ga('create', 'UA-74595852-1', 'auto');
    ga('set', 'page', url);
    ga('send', 'pageview');

    // Device
    if (device) {
      ga('set', 'dimension1', device); // Device
    }

    // User
    if (device) {
      ga('set', 'dimension4', user ? user.id : ''); // UserId
      ga('set', 'dimension5', user ? user.nickname : ''); // UserNickname
      ga('set', 'dimension6', !!user); // isLogged
    }

    ga('send', 'pageview');
  }

  static setEventOnElement(event, elementClassName, choosedEvent = 'click') {
    const element = document.getElementsByClassName(elementClassName)[0];
    if (element !== undefined) {
      element.addEventListener(choosedEvent, () => {
        Analytics.sendEvent(event);
      });
    }
  }

  static sendEvent(event) {
    const { category, action, label, value } = event;
    ga('send', 'event', category, action, label, value);
  }
}

module.exports = Analytics;
